<template>
    <Dialog :header="this.message.title" v-model:visible="this.announcementDialog" class="message-dialog" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }" :modal="true" :closeOnEscape="false" :closable="false" >
        <div class="flex align-items-center justify-content-center">
            <span style="white-space: pre-line;" v-html="setupMessage"></span>
        </div>
        <template #footer>
            <Button label="Entendido" @click="messageRead" class="p-button-raised p-button-info mr-2 mb-2" />
        </template>
    </Dialog>
</template>

<script>
    import httpRequest from '../../service/httpRequest';
    // import { computed } from 'vue'

    export default {
        data() {
            return {
                message: {},

                // Dialog
                announcementDialog: false,
            }
        },
        beforeMount() {  
        },
        created() {
            this.getBroadcastMessage();
        },
        computed: {
            setupMessage() {
                return this.message.body;
            },
        },
        methods: {
            async getBroadcastMessage() {
                const response = await httpRequest.getBroadcastMessage();
                if (response.status === 200) {
                    this.message = response.data }
                this.announcementDialog = response.status === 200;
            },
            async messageRead() {
                this.displayMessage = false;
                const response = await httpRequest.seenBroadcastMessage(this.message.id);
                if (response.status === 200) { this.message = {} }
                this.announcementDialog = false;
            },
            
        },
    }
</script>
<style scoped lang="scss">
    @media screen and (max-width: 769px) {
        .message-dialog {
            min-width: 200px;
            max-width: 90%;
        }
    }
    @media screen and (min-width: 769px) {
        .message-dialog {
            min-width: 350px;
            max-width: 500px;
        }
    }
</style>